.App {
  text-align: center;
}

.theme {
  background-color: #6d20e9;
  min-height: 100vh;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.main-theme-color {
  background-color: #726ebb !important;
}

.wallet-connect-button {
  border-color: white !important;
  font-family: inherit !important;
  font-size: 18px !important;
}

.dashboard-overview-highlight {
  font-size: 20px;
  font-weight: bold;
}

.activity-table {
  width: 90%;
  margin: 50px auto;
}

.activity-header {
  background-color: #4451a9;
  color: white;
  line-height: 60px;
  text-align: left;
  border: 1px white;
  border-style: none none solid none;
  padding-left: 13px;
}

.product-wrapper {
  background: #15115f;
  border: 1px solid #3f4290;
  padding: 30px;
  border-radius: 20px;
  margin: 60px;
}

.input-area {
  display: flex;
  align-items: center;
  border: 1px solid #b7a7ff;
  background-color: #282470;
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 20px;
  margin: 5px;
}

.input-area input {
  padding-left: 0;
  background-color: #282470;
  border: none;
  outline: none;
}

.chat-room-id {
  background-color: #282470;
  border: none;
  outline: none;
  border: 1px solid #b7a7ff;
  padding: 10px 20px;
  color: white;
  width: -webkit-fill-available;
  height: 30px;
  background: #432f9f;
  border-radius: 10px;
  font-size: 18px;
}

.admin-wallet-address {
  padding: 10px 20px;
  color: #000000;
  width: -webkit-fill-available;
  height: 30px;
  background: #ffffff;
  border-radius: 10px;
  font-size: 18px;
}

.product-input-wrapper {
  width: 70%;
  margin: auto;
}

.product-submit-btn {
  padding: 5px !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
  margin: 5px !important;
  height: 50px !important;
}

.seller-product-submit-btn {
  padding: 5px !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
  margin: 5px !important;
  height: 50px !important;
}

.seller-product-submit-btn:disabled {
  padding: 5px !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
  margin: 5px !important;
  height: 50px !important;
  background-color: gray !important;
}

.create-chat-btn {
  padding: 5px !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
  margin: 5px !important;
  height: 50px !important;
  background-color: brown !important;
}

.goto-chat-btn {
  padding: 5px !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
  margin: 5px !important;
  height: 50px !important;
  background-color: rgb(255, 255, 255, 0.2) !important;
}

.seller-product-releasefund-btn {
  width: 50%;
  padding: 5px !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
  margin: 5px !important;
  height: 50px !important;
}

.seller-product-releasefund-btn:disabled {
  width: 50%;
  padding: 5px !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
  margin: 5px !important;
  height: 50px !important;
  background-color: gray !important;
}

.walletAddress {
  color: white;
  margin: auto 3px;
  width: max-content;
}

.currency-type {
  border: 1px solid #b7a7ff !important;
  border-radius: 10px;
  padding: 5px !important;
  margin: 5px !important;
  outline: none;
  color: white;
  background: #432f9f;
  text-align: left;
  width: 97% !important;
}

.chat-input-wrapper {
  margin: auto;
  border: 1px solid white;
  border-radius: 10px;
  width: 69%;
}

.message-body {
  text-align: left;
  margin-left: 70px;
  margin-top: 0;
  font-size: 20px;
}

.message-textfield {
  width: 98%;
  position: absolute !important;
  bottom: 25px;
  right: 3px;
  padding: 0 5px;
  margin: 5px;
}

.margin-left-20 {
  margin-left: 20px;
}

.font-bold {
  font-weight: bold !important;
}

.width-75 {
  width: 75px;
}

.width-50 {
  width: 50px;
}

.text-white {
  color: white !important;
}

.text-gray {
  color: gray !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-5 {
  margin: 5px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-left {
  text-align: left;
}

.img-overview {
  width: 75px;
}

.justify-content-space-between {
  display: flex;
  justify-content: space-between;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
